export const columns = [
  {
    title: '开发商名称',
    dataIndex: 'name',
    ellipsis: true
  },
  {
    title: '企业代码',
    dataIndex: 'id'
  },
  {
    title: '所在地区',
    dataIndex: 'area',
    ellipsis: true
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    ellipsis: true,
    sorter: true
  },
  {
    title: '操作',
    dataIndex: 'action',
    fixed: 'right',
    scopedSlots: { customRender: 'action' }
  }
]

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"title":"开发商","width":_vm.modalWidth,"visible":_vm.visible,"loading":_vm.loading,"maskClosable":false},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('template',{slot:"footer"},[_c('a-button',{key:"back",on:{"click":_vm.handleCancel}},[_vm._v(" 取消 ")]),_c('a-button',{key:"submit",attrs:{"loading":_vm.loading,"type":"primary"},on:{"click":_vm.handleOk}},[_vm._v(" 确定 ")])],1),_c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('a-form',_vm._b({attrs:{"form":_vm.form}},'a-form',_vm.formLayout,false),[_c('a-form-item',{attrs:{"label":"开启账号权限"}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:(['enableStatus']),expression:"['enableStatus']"}],attrs:{"checked":this.open},on:{"change":_vm.switchChange}},[_c('a-icon',{attrs:{"slot":"checkedChildren","type":"check"},slot:"checkedChildren"}),_c('a-icon',{attrs:{"slot":"unCheckedChildren","type":"close"},slot:"unCheckedChildren"})],1)],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.open),expression:"open"}],attrs:{"label":"开发商用户名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'username',
            {
              rules: [
                { max: 30, message: '账号长度最多为30位' }
              ]
            }
          ]),expression:"[\n            'username',\n            {\n              rules: [\n                { max: 30, message: '账号长度最多为30位' }\n              ]\n            }\n          ]"}],attrs:{"allow-clear":"","placeholder":"请输入开发商用户名"}})],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.open),expression:"open"}],attrs:{"label":"开发商密码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'password'
          ]),expression:"[\n            'password'\n          ]"}],attrs:{"allow-clear":"","placeholder":"请输入开发商密码"}})],1),_c('a-form-item',{attrs:{"label":"开发商名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'name',
            {
              rules: [
                { required: true, message: '请输入开发商名称' },
                { max: 15, message: '开发商名称长度最多为15位' }
              ]
            }
          ]),expression:"[\n            'name',\n            {\n              rules: [\n                { required: true, message: '请输入开发商名称' },\n                { max: 15, message: '开发商名称长度最多为15位' }\n              ]\n            }\n          ]"}],attrs:{"allow-clear":"","placeholder":"请输入开发商名称"}})],1),_c('a-form-item',{attrs:{"label":"所在地区"}},[_c('a-cascader',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'area',
            {
              rules: [{ required: true, message: '请选择所在地区' }]
            }
          ]),expression:"[\n            'area',\n            {\n              rules: [{ required: true, message: '请选择所在地区' }]\n            }\n          ]"}],attrs:{"allow-clear":"","fieldNames":_vm.cityField,"placeholder":"请选择所在地区","options":_vm.options},on:{"change":_vm.onChange}})],1),_c('a-form-item',{attrs:{"label":"合同上传","extra":"支持扩展名：.rar .zip .doc .docx .pdf .jpg..."}},[_c('upload-oss',{attrs:{"media":_vm.media},on:{"emitFiles":_vm.getFileList}})],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }
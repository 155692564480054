<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="搜索">
                <a-input v-model="queryParam.keyword" placeholder="请输入搜索信息" />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <span class="table-page-search-submitButtons">
                <a-button type="primary" @click="reload">查询</a-button>
                <a-button style="margin-left: 8px" @click="resetReload">重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
      </div>

      <s-table
        ref="table"
        size="default"
        :rowKey="record => record.id"
        :columns="columns"
        :data="developerData"
        :totalCount="totalCount"
        :alert="true"
        :scroll="defaultScroll"
        showPagination="auto"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <span slot="description" slot-scope="text">
          <ellipsis :length="4" tooltip>{{ text }}</ellipsis>
        </span>

        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="handleEdit(record)">编辑</a>
          </template>
        </span>
      </s-table>

      <create-developer
        ref="createModal"
        @reload="reload"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import {
  findDevelopers
} from '@/api/developers'

import CreateDeveloper from './components/DeveloperCreate'
import { columns } from './js/index'

export default {
  components: {
    STable,
    Ellipsis,
    CreateDeveloper
  },
  data() {
    return {
      noticeTitle: '开发商管理',
      totalCount: 0,
      columns,
      queryParam: {},
      developerData: parameter => {
        const param = {
          keyword: ''
        }
        const params = this.paramFormat(parameter, param)
        Object.assign(params, this.queryParam)
        return findDevelopers(params)
          .then(res => {
            const result = {
              data: res._embedded.developers,
              pageNo: res.page.number,
              pageSize: res.page.size,
              totalCount: res.page.totalElements,
              totalPages: res.page.totalPages
            }
            result.data.forEach(item => {
              item.area = []
              item.area.push(item.province)
              item.area.push('-' + item.city)
              item.area.push('-' + item.district)
              item.area.push('-' + item.street)
            })
            this.totalCount = result.totalCount
            return result
          })
      }
    }
  },
  computed: {
    serial() {
      return this.$store.state.env.serial
    },
    host() {
      return this.$store.state.oss.host
    }
  },
  methods: {
    reload() {
      this.tableHome()
      this.$refs.table.refresh()
    },
    resetReload() {
      this.queryParam = {}
      this.reload()
    },
    resetTable(handle, msg = null, type = 'success') {
      switch (handle) {
        case 'del':
          if (!msg) {
            msg = '删除成功。'
          }
          this.del.confirmLoading = false
          this.del.visible = false
          break
      }
      this.reload()
      this.$notification[type]({
        message: this.noticeTitle,
        description: msg
      })
    },
    handleAdd() {
      this.$refs.createModal.showModal()
    },
    handleEdit(record) {
      this.$refs.createModal.showModal(record)
    }
  }
}
</script>

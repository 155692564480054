<template>
  <a-modal
    title="开发商"
    :width="modalWidth"
    :visible="visible"
    :loading="loading"
    :maskClosable="false"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <template slot="footer">
      <a-button key="back" @click="handleCancel">
        取消
      </a-button>
      <a-button key="submit" :loading="loading" type="primary" @click="handleOk">
        确定
      </a-button>
    </template>
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-form-item label="开启账号权限">
          <a-switch
            v-decorator="['enableStatus']"
            @change="switchChange"
            :checked="this.open"
          >
            <a-icon slot="checkedChildren" type="check" />
            <a-icon slot="unCheckedChildren" type="close" />
          </a-switch>
        </a-form-item>
        <a-form-item v-show="open" label="开发商用户名">
          <a-input
            allow-clear
            placeholder="请输入开发商用户名"
            v-decorator="[
              'username',
              {
                rules: [
                  { max: 30, message: '账号长度最多为30位' }
                ]
              }
            ]"
          />
        </a-form-item>
        <a-form-item v-show="open" label="开发商密码">
          <a-input
            allow-clear
            placeholder="请输入开发商密码"
            v-decorator="[
              'password'
            ]"
          />
        </a-form-item>
        <a-form-item label="开发商名称">
          <a-input
            allow-clear
            placeholder="请输入开发商名称"
            v-decorator="[
              'name',
              {
                rules: [
                  { required: true, message: '请输入开发商名称' },
                  { max: 15, message: '开发商名称长度最多为15位' }
                ]
              }
            ]"
          />
        </a-form-item>
        <a-form-item label="所在地区">
          <a-cascader
            allow-clear
            v-decorator="[
              'area',
              {
                rules: [{ required: true, message: '请选择所在地区' }]
              }
            ]"
            :fieldNames="cityField"
            placeholder="请选择所在地区"
            :options="options"
            @change="onChange"
          />
        </a-form-item>
        <a-form-item label="合同上传" extra="支持扩展名：.rar .zip .doc .docx .pdf .jpg...">
          <upload-oss :media="media" @emitFiles="getFileList"></upload-oss>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { UploadOss } from '@/components'
import { uploadOssBatch } from '@/utils/upload'
import {
  // getDevelopers,
  editDevelopers,
  // getDevelopersContract,
  updateDevelopers,
  putDevelopersContract,
  getDevelopers
} from '@/api/developers'
import { THUMBNAIL_ORIGIN, THUMBNAIL_VEDIOCOVER } from '@/common/const'

// 表单字段
const fields = ['name', 'area', 'username', 'password']

export default {
  components: {
    UploadOss
  },
  data() {
    return {
      noticeTitle: '开发商管理',
      model: null,
      media: [],
      form: this.$form.createForm(this),
      visible: false,
      enableStatus: false,
      open: false,
      loading: false,
      cityField: { label: 'name', value: 'name', children: 'children' }
    }
  },
  computed: {
    options() {
      const city = this.defaultCityOptions
      if (this.$store.state.city.city) {
        return this.$store.state.city.city
      } else {
        return city
      }
    },
    host() {
      return this.$store.state.oss.host
    }
  },
  created() {
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))

    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  },
  methods: {
    reload() {
      this.$emit('reload')
    },
    showModal(record = null) {
      this.loading = false
      this.model = null
      this.form.resetFields()
      this.media = []
      this.open = false
      if (record) {
        this.handleEdit(record)
      } else {
        this.visible = true
      }
    },
    handleCancel() {
      this.visible = false
    },
    fetchInfo() {},
    onChange(value) {
      console.log(value, 'city')
    },
    switchChange(checked) {
      this.open = checked
    },
    getFileList(data) {
      this.media = data
    },
    handleEdit(record) {
      this.loading = true
      this.visible = true
      this.media = record.contract.map(item => {
        const file = {
          uid: item.id,
          name: item.path.slice(item.path.lastIndexOf('/') + 1),
          status: 'done',
          path: item.path,
          sort: item.sort
        }
        const path = `${this.host}${item.path}`
        if (item.type === 'video/mp4') {
          file.url = `${path}${THUMBNAIL_VEDIOCOVER}`
          file.src = path
        } else {
          file.url = `${path}${THUMBNAIL_ORIGIN}`
        }
        return file
      })
      getDevelopers(record.id).then(res => {
        this.model = res
        this.open = res.enableStatus
        console.log(res, 'res');
        console.log(this.model, 'this.model');
        this.model.area = [record.province, record.city, record.district, record.street]
        if (this.open) {
          this.form.setFieldsValue({
            username: res.username,
            password: res.password
          })
        }
        this.loading = false
      })
    },
    handleOk() {
      this.form.validateFields((errors, values) => {
        if (!errors) {
          this.loading = true
          setTimeout(() => {
            if (this.visible) {
              const area = values.area
              const params = {
                ...values,
                province: area[0],
                city: area[1],
                district: area[2],
                street: area[3]
              }
              if (this.open) {
                params.enableStatus = true
              } else {
                params.enableStatus = false
                params.username = ''
                params.password = ''
              }
              if (this.model === null) {
                this.addHandle(params)
              } else {
                // 新增/修改
                this.editHandle(this.model.id, params)
              }
            } else {
              this.loading = false
            }
          }, this.asyncTime)
        } else {
          this.loading = false
        }
      })
    },
    editHandle(id, requestParameters) {
      const { media } = this
      editDevelopers(id, requestParameters).then(res => {
        this.fileListUpload(id, media, '编辑成功')
      }).catch(() => {
        this.loading = false
      })
    },
    addHandle(requestParameters) {
      const { media } = this
      updateDevelopers(requestParameters).then(res => {
        console.log(res, 'res add handle');
        const data = res.data
        const id = data.id
        this.fileListUpload(id, media, '新建成功')
      }).catch(() => {
        this.loading = false
      })
    },
    fileListUpload(id, mediaArr = [], msg = '成功', index = 0) {
      const mediaList = mediaArr.map(item => {
        if (item.originFileObj === undefined) {
          return item
        } else {
          return item.originFileObj
        }
      })
      uploadOssBatch(mediaList, '/contracts/img/' + id).then(mediaRes => {
        console.log(mediaRes, 'mediaRes')
        if (mediaRes) {
          const len = mediaRes.length
          const urls = mediaRes
            .map(item => {
              if (item._links) {
                return item._links.self.href
              } else {
                return this.serial + '/rest/medias/' + item.uid
              }
            })
            .join('\n')
          putDevelopersContract(id, urls).then(res2 => {
            if (len === 0 || index + 1 === mediaArr.length) {
              this.visible = false
              this.reload()
              this.$notification.success({
                message: this.noticeTitle,
                description: msg
              })
            } else {
              this.fileListUpload(id, mediaArr, msg, index + 1)
            }
          })
        }
      })
    }
  }
}
</script>
